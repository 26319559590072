export default {
    baseURL: 'https://test.leslunettesoho.com',
    login: '/api/v1/auth/login',
    allAgency: '/api/v1/agency/get-agency',
    swich: '/api/v1/auth/switch-agency',
    getProduct: '/api/v1/product/get-products',
    allCompany: '/api/v1/insurance/get-insurance',
    getCommande: '/api/v1/technique/get-orders',
    getCommandePaginate: '/api/v1/technique/get-paginated-orders',
    validArticle: '/api/v1/coordination/validate-order-article',
    validation: '/api/v1/technique/validate-order',
    getExport: '/api/v1/technique/get-exported-articles',
    exportLite: '/api/v1/crons/lite-exported',
    mesure: '/api/v1/estimate/sell/add-estimate-sub-data',
    createExport: '/api/v1/technique/create-export-file',
    assemblyListe: '/api/v1/technique/get-order-for-assembly',
    validateAssembly: '/api/v1/technique/validate-assembly',
    createFacture: '/api/v1/accounting/create-internal-invoice',
    factureValidation: '/api/v1/technique/validate-tech-invoice-article',
    allGlass: '/api/v1/technique/get-tech-internal-invoice',
    changePassword: '/api/v1/auth/update-password'
}
